@import "~styles/utils/modules-entry.scss";

/*
  For breakpoint-md (tablets), show the links as compact buttons with labels below icons.
  For breakpoint-lg (desktop), show the links as traditional link with icon to the left.
*/
.link {
  cursor: pointer;
  line-height: 1.6;
  text-align: center;
  color: var(--gray-mid);
  transition: color 0.15s;
  padding: 0.5rem;
  flex-direction: column;

  &.linkActive {
    color: theme-color(primary);
  }

  &:hover .title {
    text-decoration: underline;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 0.8rem 2rem;
  }

  @include media-breakpoint-up(lg) {
    gap: 0.5rem;
    flex-direction: row;
  }
}

.title {
  display: block;
  max-width: 100%;
}

/* .hiddenOnMobile { */
/*   @include media-breakpoint-down(sm) { */
/*     display: none; */
/*   } */
/* } */

.divider {
  $size: 70%;
  $margin: 0.3rem;

  opacity: 0.6;
  flex: 0 0 1px;
  margin: $margin ((100% - $size) * 0.5) 0;
  background: var(--gray-lighter);

  @include media-breakpoint-up(xl) {
    margin: $margin 1rem $margin 1.4rem;
  }
}

.updateDot {
  position: absolute;
  top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin-left: 1rem;
  border-radius: 50%;
  font-size: $font-size-xs;
  line-height: 1.2;
  background: theme-color(success);

  :global {
    animation: zoomIn 0.4s;
  }

  @include media-breakpoint-up(md) {
    right: 1.4rem;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}
