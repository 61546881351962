// This file contains the styles for the overall site

html,
body,
#app,
.app-container {
  // Ensures the page always fill the height of the page. Combined with the
  // flexbox on .app-container, this allows the footer to be aligned to the
  // page bottom when there's insufficient page content
  height: 100%;
}

body {
  /* padding-top: $navbar-height + 1rem; */

  @include media-breakpoint-down(sm) {
    /* padding-top: $navbar-height; */
    font-size: 1rem * $sm-font-size-ratio;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
}

.main-container {
  flex: 1 auto;
  padding: 0; // For Microsoft Edge 18
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  @include media-breakpoint-down(sm) {
    .main-content {
      padding-top: 1rem;
    }
  }

  @include media-breakpoint-up(md) {
    > nav {
      width: calc(#{$side-nav-width-md} + #{($grid-gutter-width * 0.5)});
    }

    .main-content {
      padding-left: $side-nav-width-md;
    }
  }

  @include media-breakpoint-up(xl) {
    > nav {
      width: $side-nav-width-lg;
    }

    .main-content {
      padding-left: $side-nav-width-lg;
    }
  }
}

.page-container {
  animation-duration: $page-entering-duration;

  @extend .container-fluid;
}
