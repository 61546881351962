@import "~styles/utils/modules-entry.scss";

$vert-padding: 0.5rem;
// Keep padding at whole number, 0.875rem = 14px
// See: https://github.com/nusmodifications/nusmods/pull/1567
$logo-vert-padding: 0.875rem;

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $navbar-height;
  padding: 0; // For Microsoft Edge 18
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  background: var(--gray-lightest);
}

.brand {
  display: flex;
  padding-left: 1.5em;
}

.brandLogo {
  width: 100%;
}

.navRight {
  display: flex;
  align-items: center;
  padding-right: 0.5em;
}

@include media-breakpoint-up(xl) {
  .brand {
    // Padding to match navtab left padding
    padding-left: 2rem;
  }

  .navRight {
    padding: $vert-padding $grid-gutter-width * 0.5 $vert-padding 2rem;
  }
}
