@import "~styles/utils/modules-entry";

// Single element loader style from https://github.com/lukehaas/css-loaders
// loader-8 with modifications
.loader,
.loader::after {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.loader.small,
.loader.small::after {
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
}

.loader {
  position: relative;
  margin: 4rem auto;
  border: 0.4rem solid rgba(theme-color(), 0.2);
  border-left-color: theme-color();
  transform: translateZ(0);

  :global {
    animation: spin 1.1s infinite linear;
  }

  &.small {
    margin: 0 0 -0.1rem 0;
    border-width: 0.2rem;
  }

  &.white {
    border-color: rgba(#fff, 0.2);
    border-left-color: #fff;
  }
}
