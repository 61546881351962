@import "~styles/utils/modules-entry.scss";

.container {
  composes: page-container text-center from global;
  max-width: 40rem;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
  }
}

.header {
  margin-bottom: 1.5rem;
}

.expr {
  composes: text-primary from global;
  display: block;
  font-size: 2.5rem;
}

:global(.btn).link {
  padding: 0;
  margin: 0;
  line-height: 1;
  vertical-align: baseline;
  cursor: pointer;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  :global(.btn) {
    margin-bottom: 0.8rem;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: center;

    :global(.btn) {
      margin-right: 1em;
      margin-bottom: 0;
    }

    :global(.btn):last-child {
      margin-right: 0;
    }
  }
}

.bigCharacter {
  padding: 0.1rem;
  font-weight: bolder;
  font-size: 8rem;
  // prettier-ignore
  text-shadow:
    3px  3px  0 theme-color(),
    -1px -1px 0 theme-color(),
    1px  -1px 0 theme-color(),
    -1px 1px  0 theme-color(),
    1px  1px  0 theme-color();
  color: black;
}
